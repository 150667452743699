import React from 'react';
import '../styles/text.less';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';
import Obfuscate from 'react-obfuscate';
import Layout from '../components/Layout';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      portraitImage: file(relativePath: { eq: "lineareal_axel_fahrenhorst.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const intl = useIntl();

  return (
    <Layout>
      <div className="textcontent contact">
        <h1 className="colorHighlight">
          {/* {intl.formatHTMLMessage({ id: 'contact.headline' })} */}
          <FormattedHTMLMessage id="contact.headline" />
        </h1>

        {/* <address>
          <div className="cols">
            <div className="col col_1">
              <FormattedHTMLMessage id="contact.address" />
            </div>

            <div className="col col_2">

              <FormattedHTMLMessage id="contact.phone" />
              <br />
              <Obfuscate email={intl.formatHTMLMessage({ id: 'contact.mail' })} />
            </div>

            <div className="col col_3" />

          </div>
        </address> */}

        <address>

          <p>
            <FormattedHTMLMessage id="contact.address" />
          </p>


          <p>
            <FormattedHTMLMessage id="contact.phone" />
          </p>

          <p>
            <Obfuscate style={{ display: 'inline-block' }} email={intl.formatHTMLMessage({ id: 'contact.mail' })} />
          </p>


        </address>
      </div>
    </Layout>
  );
};

export default Contact;
